import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import OmeMeetContextProvider from '../../state/omeMeet/OmeMeetContextProvider';
import OmeMeetOrgUnitEntryContextProvider from '../../state/omeMeetOrgUnitEntry/OmeMeetOrgUnitEntryContextProvider';
import OmeMeetTeamEntryContextProvider from '../../state/omeMeetTeamEntry/OmeMeetTeamEntryContextProvider';
import OmeMeetTeamEntryFiltersContextProvider from '../../state/omeMeetTeamEntry/OmeMeetTeamEntryFiltersContextProvider';
import OmeMeetOrgUnitAthleteEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntry/OmeMeetOrgUnitAthleteEntryContextProvider';
import OmeMeetOrgUnitEntryPurchaseContextProvider from '../../state/omeMeetOrgUnitEntryPurchase/OmeMeetOrgUnitEntryPurchaseContextProvider';
import OrgUnitAthleteEntryPurchaseContextProvider from '../../state/orgUnitAthleteEntryPurchase/OrgUnitAthleteEntryPurchaseContextProvider';
import OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider from '../../state/omeMeetOrgUnitAthleteEntryRosterSelection/OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider';
import OmeMeetOrgUnitNonAthleteEntryContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntry/OmeMeetOrgUnitNonAthleteEntryContextProvider';
import OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntryCoachesSelection/OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider';
import OmeMeetOrgUnitEntryContactContextProvider from '../../state/omeMeetOrgUnitEntryContact/OmeMeetOrgUnitEntryContactContextProvider';
import OrgGroupContextProvider from '../../state/orgGroup/OrgGroupContextProvider';
import OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider';
import OrgUnitAthleteEntryIndividualRosterEntryContextProvider from '../../state/orgUnitAthleteEntry/OrgUnitAthleteEntryIndividualRosterEntryContextProvider';
import OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntryRelayRosterEntry/OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider';
import RelayAthleteMeetEventContextProvider from '../../state/relayAthleteMeetEvent/RelayAthleteMeetEventContextProvider';
import RelayAthleteEntryTimeContextProvider from '../../state/relayAthleteEntryTime/RelayAthleteEntryTimeContextProvider';
import OmeMeetAthleteEntryContextProvider from '../../state/omeMeetAthleteEntry/OmeMeetAthleteEntryContextProvider';
import OmeMeetAthleteEntryFiltersContextProvider from '../../state/omeMeetAthleteEntry/OmeMeetAthleteEntryFiltersContextProvider';
import MeetCompetitionSummaryContextProvider from '../../state/meetCompetitionSummary/MeetCompetitionSummaryContextProvider';
import OUAdminEntryContextContextProvider from '../../state/ouAdminEntryContextView/OUAdminEntryContextContextProvider';
import EventMeetEventBonusContextProvider from '../../state/eventMeetEventBonus/EventMeetEventBonusContextProvider';
import EventMeetEventContextProvider from '../../state/eventMeetEvent/EventMeetEventContextProvider';
import AthleteEntryContextContextProvider from '../../state/athleteEntryContextView/AthleteEntryContextContextProvider';
import MyMeetsFiltersContextProvider from '../../state/myMeetsFilters/MyMeetsFiltersContextProvider';
import OmeMeetOrgUnitEntryTimesContextProvider from '../../state/omeMeetOrgUnitEntryTimes/OmeMeetOrgUnitEntryTimesContextProvider';
import OrgUnitOmeMeetContextProvider from '../../state/orgUnitOmeMeet/OrgUnitOmeMeetContextProvider';
import OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider from '../../state/omeMeetOrgUnitAthleteEntryAggregateRelayAthlete/OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider';
import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntry/OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider';
import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection/OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider';
import ApprovedClubsForSecondaryRepContextProvider from '../../state/approvedClubsForSecondaryRep/ApprovedClubsForSecondaryRepContextProvider';
import OmeMeetInvitationsContextProvider from '../../state/omeMeetInvitations/OmeMeetInvitationsContextProvider';

import MeetContextProvider from '../../../common/state/meet/MeetContextProvider';
import MeetFiltersContextProvider from '../../../common/state/meet/MeetFiltersContextProvider';
import SearchMeetContextProvider from '../../../common/state/searchMeet/SearchMeetContextProvider';
import MeetStaffContextProvider from '../../../common/state/meet/meetStaff/MeetStaffContextProvider';
import MeetTimesAddContextProvider from '../../../common/state/meet/meetTimes/meetTimesAdd/MeetTimesAddContextProvider';
import MeetTimesAddPostContextProvider from '../../../common/state/meet/meetTimes/meetTimesAdd/MeetTimesAddPostContextProvider';
import MeetSessionsContextProvider from '../../../common/state/meetSessions/MeetSessionsContextProvider';
import TimeContextProvider from '../../../common/state/time/TimeContextProvider';
import TimeFiltersContextProvider from '../../../common/state/time/TimeFiltersContextProvider';
import TimesOrganizationContextProvider from '../../../common/state/time/TimesOrganizationContextProvider';
import SessionContextProvider from '../../../common/state/time/SessionContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import OtsMeetSessionsContextProvider from '../../../common/state/officialsTrackingSystemMeetSessions/OtsMeetSessionsContextProvider';
import EventCompetitonGenderTypeContextProvider from '../../../common/state/eventCompetitionGender/EventCompetitionGenderTypeContextProvider';
import MeetResultsStatusContextProvider from '../../../common/state/meet/meetResultsUploadStatus/MeetResultsStatusContextProvider';

import MeetContextView from './MeetContextView';
import OUAdminEntryContextView from '../entry/orgUnitAdminUser/OUAdminEntryContextView';
import AthleteEntryContextView from '../entry/athleteUser/AthleteEntryContextView';

import MeetById from './meetById/MeetById';
import MeetAdd from './meetAdd/MeetAdd';
import MeetAddSessions from './meetAdd/MeetAddSessions';
import MeetAddSessionsDetail from './meetAdd/MeetAddSessionsDetail';
import MeetAddStaff from './meetAdd/MeetAddStaff';
import MeetSearchWrite from './meetSearch/MeetSearchWrite';
import MeetSearchReadOnly from './meetSearch/MeetSearchReadOnly';
import SimplifiedMeetSearch from './meetSearch/SimplifiedMeetSearch';
import MeetInfoWrite from './meetInfo/MeetInfoWrite';
import ExportEntries from './meetHost/ExportEntries';
import MeetHostReporting from './meetHost/MeetHostReporting';
import MeetInfoRead from './meetInfo/MeetInfoRead';
import MeetSessionsWrite from './meetSessions/MeetSessionsWrite';
import MeetSessionsRead from './meetSessions/MeetSessionsRead';
import MeetSessionsDetail from './meetSessions/MeetSessionsDetail';
import MeetTimes from './meetTimes/meetTimes/MeetTimes';
import MeetTimesReadOnly from './meetTimes/meetTimes/MeetTimesReadOnly';
import MeetUpload from './meetUpload/MeetUpload';
import MeetUploadLock from './meetUploadLock/MeetUploadLock';
import MeetRecon from './meetRecon/MeetRecon';
import MeetReporting from './meetReporting/MeetReporting'
import TimesRecon from './timesRecon/TimesRecon';
import MeetUploadErrorTank from './meetUploadErrorTank/MeetUploadErrorTank';
import MeetRecognition from './meetRecognition/MeetRecognition';
import MeetStaffWrite from './meetStaff/MeetStaffWrite';
import MeetStaffRead from './meetStaff/MeetStaffRead';
import MeetStaffDetail from './meetStaff/MeetStaffDetail';
import MeetTimesAddAthleteAddMeetTimeSelectTimeType from './meetTimes/meetTimeSelectTimeType/AddMeetTimeSelectTimeType';
import MeetTimesAddMeetTime from './meetTimes/meetTimesAddTime/AddMeetTime';
import MeetTimesAddMeetTimeRelay from './meetTimes/addMeetTimeRelay/AddMeetTimeRelay';
import MeetTimesAddMeetTimeIndividual from './meetTimes/addMeetTimeIndividual/AddMeetTimeIndividual';
import MeetHostContextStateAndRoutes from './meetHost/MeetHostContextStateAndRoutes';
import MeetTeamEntry from './meetTeamEntry/MeetTeamEntry';
import MeetTeamEntryNonHQ from './meetTeamEntry/MeetTeamEntryNonHQ';
import TeamEntryOrgUnitSelection from './meetTeamEntry/teamEntryOrgUnitSelection/TeamEntryOrgUnitSelection';
import TeamEntryHQOrgUnitSelection from './meetTeamEntry/teamEntryHQOrgUnitSelection/TeamEntryHQOrgUnitSelection';
import MeetAthleteEntry from './meetAthleteEntry/MeetAthleteEntry';
import OrgUnitAdminLanding from '../entry/orgUnitAdminUser/orgUnitAdminLanding/OrgUnitAdminLanding';
import OrgUnitAdminLandingReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminLanding/OrgUnitAdminLandingReadOnly';
import OrgUnitAdminContactsDetail from '../entry/orgUnitAdminUser/orgUnitAdminContacts/OrgUnitAdminContactsDetail';
import OrgUnitAdminContacts from '../entry/orgUnitAdminUser/orgUnitAdminContacts/OrgUnitAdminContacts';
import OrgUnitAdminContactsReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminContacts/OrgUnitAdminContactsReadOnly';
import OrgUnitAdminRosterPermissions from '../entry/orgUnitAdminUser/orgUnitAdminRosterPermissions/OrgUnitAdminRosterPermissions';
import OrgUnitAdminRosterPermissionsReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminRosterPermissions/OrgUnitAdminRosterPermissionsReadOnly';
import OrgUnitAdminRoster from '../entry/orgUnitAdminUser/orgUnitAdminRoster/OrgUnitAdminRoster';
import OrgUnitAdminRosterReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminRoster/OrgUnitAdminRosterReadOnly';
import OrgUnitAdminRosterSelection from '../entry/orgUnitAdminUser/orgUnitAdminRoster/OrgUnitAdminRosterSelection';
import OrgUnitAdminRosterEntries from '../entry/orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntries';
import OrgUnitAdminRosterEntriesReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesReadOnly';
import OrgUnitAdminRosterEntriesDetail from '../entry/orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesDetail';
import OrgUnitAdminRelayEntries from '../entry/orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntries';
import OrgUnitAdminRelayEntriesReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesReadOnly';
import OrgUnitAdminRelayEntriesTeams from '../entry/orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesTeams';
import OrgUnitAdminRelayEntriesTeamsReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesTeamsReadOnly';
import OrgUnitAdminRelayEntriesDetail from '../entry/orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesDetail';
import OrgUnitAdminRelayEntriesDetailOverride from '../entry/orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesDetailOverride';
import OrgUnitAdminRosterEntriesIndividualSelection from '../entry/orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesIndividualSelection';
import OrgUnitAdminRosterEntriesBonusIndividualSelection from '../entry/orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesBonusIndividualSelection';
import OrgUnitAdminRosterEntriesIndividualOverride from '../entry/orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesIndividualOverride';
import OrgUnitAdminRosterEntriesBonusIndividualOverride from '../entry/orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesBonusIndividualOverride';
import OrgUnitAdminCoaches from '../entry/orgUnitAdminUser/orgUnitAdminCoaches/OrgUnitAdminCoaches';
import OrgUnitAdminCoachesReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminCoaches/OrgUnitAdminCoachesReadOnly';
import OrgUnitAdminCoachesSelection from '../entry/orgUnitAdminUser/orgUnitAdminCoaches/OrgUnitAdminCoachesSelection';
import OrgUnitAdminPurchases from '../entry/orgUnitAdminUser/orgUnitAdminPurchases/OrgUnitAdminPurchases';
import OrgUnitAdminPurchasesReadOnly from '../entry/orgUnitAdminUser/orgUnitAdminPurchases/OrgUnitAdminPurchasesReadOnly';
import OrgUnitAdminPayments from '../entry/orgUnitAdminUser/orgUnitAdminPayments/OrgUnitAdminPayments';
import OrgUnitAdminOfficialsWrite from '../entry/orgUnitAdminUser/orgUnitAdminOfficials/OrgUnitAdminOfficialsWrite';
import OrgUnitAdminReporting from '../entry/orgUnitAdminUser/orgUnitAdminReporting/OrgUnitAdminReporting';
import RaceStatsUpload from './raceStatsUpload/RaceStatsUpload';
import RaceStats from './raceStats/RaceStats';
import TimesValidation from './timesValidation/TimesValidation';
import TimesValidationDetail from './timesValidation/TimesValidationDetail';

import AthleteUserEntriesDetail from '../entry/athleteUser/athleteUserEntries/AthleteUserEntriesDetail';
import AthleteUserEntriesDetailReadOnly from '../entry/athleteUser/athleteUserEntries/AthleteUserEntriesDetailReadOnly';
import AthleteUserEntriesIndividualSelection from '../entry/athleteUser/athleteUserEntries/AthleteUserEntriesIndividualSelection';
import AthleteUserEntriesBonusIndividualSelection from '../entry/athleteUser/athleteUserEntries/AthleteUserEntriesBonusIndividualSelection';
import AthleteUserEntriesIndividualOverride from '../entry/athleteUser/athleteUserEntries/AthleteUserEntriesIndividualOverride';
import AthleteUserEntriesBonusIndividualOverride from '../entry/athleteUser/athleteUserEntries/AthleteUserEntriesBonusIndividualOverride';
import AthleteUserCoaches from '../entry/athleteUser/athleteUserCoaches/AthleteUserCoaches';
import AthleteUserCoachesReadOnly from '../entry/athleteUser/athleteUserCoaches/AthleteUserCoachesReadOnly';
import AthleteUserCoachesSelection from '../entry/athleteUser/athleteUserCoaches/AthleteUserCoachesSelection';
import AthleteUserPurchases from '../entry/athleteUser/athleteUserPurchases/AthleteUserPurchases';
import AthleteUserPurchasesReadOnly from '../entry/athleteUser/athleteUserPurchases/AthleteUserPurchasesReadOnly';
import AthleteUserPayments from '../entry/athleteUser/athleteUserPayments/AthleteUserPayments';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const OUAdminEntryContextProviders = ({ children }) => (
  <OmeMeetOrgUnitEntryContactContextProvider>
    <OmeMeetOrgUnitEntryTimesContextProvider>
      <OmeMeetOrgUnitEntryPurchaseContextProvider>
        <OmeMeetOrgUnitAthleteEntryContextProvider>
          <OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider>
            <OmeMeetOrgUnitNonAthleteEntryContextProvider>
              <OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider>
                <OrgGroupContextProvider>
                  <OrgUnitAthleteEntryIndividualRosterEntryContextProvider>
                    <OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider>
                      <OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider>
                        <RelayAthleteMeetEventContextProvider>
                          <RelayAthleteEntryTimeContextProvider>
                            <EventMeetEventContextProvider>
                              <EventMeetEventBonusContextProvider>

                                <AthleteEntryContextContextProvider>
                                  <OUAdminEntryContextContextProvider>
                                    <ApprovedClubsForSecondaryRepContextProvider>
                                      {children}
                                    </ApprovedClubsForSecondaryRepContextProvider>
                                  </OUAdminEntryContextContextProvider>
                                </AthleteEntryContextContextProvider>

                              </EventMeetEventBonusContextProvider>
                            </EventMeetEventContextProvider>
                          </RelayAthleteEntryTimeContextProvider>
                        </RelayAthleteMeetEventContextProvider>
                      </OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider>
                    </OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider>
                  </OrgUnitAthleteEntryIndividualRosterEntryContextProvider>
                </OrgGroupContextProvider>
              </OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider>
            </OmeMeetOrgUnitNonAthleteEntryContextProvider>
          </OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider>
        </OmeMeetOrgUnitAthleteEntryContextProvider>
      </OmeMeetOrgUnitEntryPurchaseContextProvider>
    </OmeMeetOrgUnitEntryTimesContextProvider>
  </OmeMeetOrgUnitEntryContactContextProvider>
);

const OUAdminEntryContextRoutes = ({ navRoutes }) => (
  <OUAdminEntryContextProviders>
    <OUAdminEntryContextView>
      <Routes>
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_LANDING']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_LANDING']}
              editElement={<OrgUnitAdminLanding />}
              readOnlyElement={<OrgUnitAdminLandingReadOnly />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_CONTACTS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_CONTACTS']}
              editElement={<OrgUnitAdminContacts />}
              readOnlyElement={<OrgUnitAdminContactsReadOnly />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_CONTACTS_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_CONTACTS_DETAIL']}
              editElement={<OrgUnitAdminContactsDetail />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTERPERMISSIONS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTERPERMISSIONS']}
              editElement={<OrgUnitAdminRosterPermissions />}
              readOnlyElement={<OrgUnitAdminRosterPermissionsReadOnly />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER']}
              editElement={<OrgUnitAdminRoster />}
              readOnlyElement={<OrgUnitAdminRosterReadOnly />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_SELECTION']}
              editElement={<OrgUnitAdminRosterSelection />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES']}
              editElement={<OrgUnitAdminRosterEntries />}
              readOnlyElement={<OrgUnitAdminRosterEntriesReadOnly />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_DETAIL']}
              editElement={<OrgUnitAdminRosterEntriesDetail />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_SELECTION']}
              editElement={<OrgUnitAdminRosterEntriesIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION']}
              editElement={<OrgUnitAdminRosterEntriesBonusIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE']}
              editElement={<OrgUnitAdminRosterEntriesIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']}
              editElement={<OrgUnitAdminRosterEntriesBonusIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES']}
              editElement={<OrgUnitAdminRelayEntries />}
              readOnlyElement={<OrgUnitAdminRelayEntriesReadOnly />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES_TEAMS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES_TEAMS']}
              editElement={<OrgUnitAdminRelayEntriesTeams />}
              readOnlyElement={<OrgUnitAdminRelayEntriesTeamsReadOnly />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL']}
              editElement={<OrgUnitAdminRelayEntriesDetail />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL_OVERRIDE']}
              editElement={<OrgUnitAdminRelayEntriesDetailOverride />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_COACHES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_COACHES']}
              editElement={<OrgUnitAdminCoaches />}
              readOnlyElement={<OrgUnitAdminCoachesReadOnly />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_COACHES_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_COACHES_SELECTION']}
              editElement={<OrgUnitAdminCoachesSelection />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_PURCHASES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_PURCHASES']}
              editElement={<OrgUnitAdminPurchases />}
              readOnlyElement={<OrgUnitAdminPurchasesReadOnly />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_PAYMENTS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_PAYMENTS']}
              editElement={<OrgUnitAdminPayments />} />
          } />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_OFFICIALS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_OFFICIALS']}
              editElement={<OrgUnitAdminOfficialsWrite />} />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_TEAM_OUA_TEAMENTRYREPORTING']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_TEAM_OUA_TEAMENTRYREPORTING']}
              editElement={<OrgUnitAdminReporting />} />
          }
        />
        <Route path={'/'} element={<Navigate to={navRoutes['MEET_ENTRY_TEAM_OUA_LANDING']?.route} replace />} />
      </Routes>
    </OUAdminEntryContextView>
  </OUAdminEntryContextProviders>
);

const AthleteEntryContextProviders = ({ children }) => (
  <OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider>
    <OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider>
      <OrgUnitAthleteEntryPurchaseContextProvider>
        <OrgUnitAthleteEntryIndividualRosterEntryContextProvider>
          <OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider>
            <EventMeetEventContextProvider>
              <EventMeetEventBonusContextProvider>
                {children}
              </EventMeetEventBonusContextProvider>
            </EventMeetEventContextProvider>
          </OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider>
        </OrgUnitAthleteEntryIndividualRosterEntryContextProvider>
      </OrgUnitAthleteEntryPurchaseContextProvider>
    </OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider>
  </OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider>
);

const AthleteEntryContextRoutes = ({ navRoutes }) => (
  <AthleteEntryContextProviders>
    <AthleteEntryContextView>
      <Routes>
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES']}
              editElement={<AthleteUserEntriesDetail />}
              readOnlyElement={<AthleteUserEntriesDetailReadOnly />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_SELECTION']}
              editElement={<AthleteUserEntriesIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION']}
              editElement={<AthleteUserEntriesBonusIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_OVERRIDE']}
              editElement={<AthleteUserEntriesIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']}
              editElement={<AthleteUserEntriesBonusIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_COACHES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_COACHES']}
              editElement={<AthleteUserCoaches />}
              readOnlyElement={<AthleteUserCoachesReadOnly />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_COACHES_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_COACHES_SELECTION']}
              editElement={<AthleteUserCoachesSelection />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_PURCHASES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_PURCHASES']}
              editElement={<AthleteUserPurchases />}
              readOnlyElement={<AthleteUserPurchasesReadOnly />}
            />
          }
        />
        <Route path={navRoutes['MEET_ENTRY_ATHLETE_PAYMENTS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['MEET_ENTRY_ATHLETE_PAYMENTS']}
              editElement={<AthleteUserPayments />}
            />
          }
        />
        <Route path={'/'} element={<Navigate to={navRoutes['MEET_ENTRY_ATHLETE_ENTRIES']?.route} replace />} />
      </Routes>
    </AthleteEntryContextView>
  </AthleteEntryContextProviders>
);

const ContextRoutes = ({ navRoutes }) => (
  <OrgUnitOmeMeetContextProvider>
    <MeetStaffContextProvider>
      <MeetContextView>
        <Routes>
          <Route path={navRoutes['MEET_INFO']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_INFO']}
                editElement={<MeetInfoWrite />}
                readOnlyElement={<MeetInfoRead />} />
            }
          />
          <Route path={navRoutes['BLOCK_PARTY_MEET_INFO']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['BLOCK_PARTY_MEET_INFO']}
                editElement={<MeetInfoRead />}
                readOnlyElement={<MeetInfoRead />} />
            }
          />
          <Route path={navRoutes['MEET_HOST_EXPORT_ENTRIES']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_HOST_EXPORT_ENTRIES']}
                editElement={<ExportEntries />} />
            }
          />
          <Route path={navRoutes['MEET_HOST_REPORTING']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_HOST_REPORTING']}
                editElement={<MeetHostReporting />} />
            }
          />
          <Route path={navRoutes['MEET_STAFF']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_STAFF']}
                editElement={<MeetStaffWrite />}
                readOnlyElement={<MeetStaffRead />} />
            }
          />
          <Route path={navRoutes['MEET_STAFF_DETAIL']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_STAFF_DETAIL']}
                editElement={<MeetStaffDetail />}
              />
            }
          />
          <Route path={navRoutes['MEET_TIMES']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_TIMES']}
                editElement={<MeetTimes />}
                readOnlyElement={<MeetTimesReadOnly />} />
            }
          />
          <Route path={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME']}
                editElement={<MeetTimesAddMeetTime />} />
            }
          />
          <Route path={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME_SELECT_TIME_TYPE']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME_SELECT_TIME_TYPE']}
                editElement={<MeetTimesAddAthleteAddMeetTimeSelectTimeType />} />
            }
          />
          <Route path={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME_INDIVIDUAL']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME_INDIVIDUAL']}
                editElement={<MeetTimesAddMeetTimeIndividual />} />
            }
          />
          <Route path={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME_SELECT_RELAY']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_TIMES_ADD_ATHLETE_ADD_TIME_SELECT_RELAY']}
                editElement={<MeetTimesAddMeetTimeRelay />} />
            }
          />
          <Route path={navRoutes['MEET_UPLOAD']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_UPLOAD']}
                editElement={<MeetUpload />} />
            }
          />
          <Route path={navRoutes['MEET_UPLOAD_LOCK']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_UPLOAD_LOCK']}
                editElement={<MeetUploadLock />} />
            }
          />
          <Route path={navRoutes['MEET_RECON']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_RECON']}
                editElement={<MeetRecon />} />
            }
          />
          <Route path={navRoutes['MEET_RACE_STATS_UPLOAD']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_RACE_STATS_UPLOAD']}
                editElement={<RaceStatsUpload />} />
            }
          />
          <Route path={navRoutes['MEET_RACE_STATS']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_RACE_STATS']}
                editElement={<RaceStats />} />
            }
          />
          <Route path={navRoutes['MEET_REPORTING']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_REPORTING']}
                editElement={<MeetReporting />} />
            }
          />
          <Route path={navRoutes['TIMES_RECON']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['TIMES_RECON']}
                editElement={<TimesRecon />} />
            }
          />
          <Route path={navRoutes['MEET_ERROR_TANK']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ERROR_TANK']}
                editElement={<MeetUploadErrorTank />} />
            }
          />
          <Route path={navRoutes['MEET_RECOGNITION']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_RECOGNITION']}
                editElement={<MeetRecognition />} />
            }
          />
          <Route path={navRoutes['MEET_TIMES_VALIDATION']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_TIMES_VALIDATION']}
                editElement={<TimesValidation />} />
            }
          />
          <Route path={navRoutes['MEET_TIMES_VALIDATION_DETAIL']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_TIMES_VALIDATION_DETAIL']}
                editElement={<TimesValidationDetail />} />
            }
          />
          <Route path={navRoutes['MEET_SESSIONS']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_SESSIONS']}
                editElement={<MeetSessionsWrite />}
                readOnlyElement={<MeetSessionsRead />}
              />}
          />
          <Route path={navRoutes['MEET_SESSIONS_DETAIL']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_SESSIONS_DETAIL']}
                editElement={<MeetSessionsDetail />} />
            }
          />
          <Route path={navRoutes['MEET_HOST']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_HOST']}
                editElement={<MeetHostContextStateAndRoutes navRoutes={navRoutes} />} />
            }
          />
          <Route path={navRoutes['MEET_ENTRY_TEAM']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ENTRY_TEAM']}
                editElement={<MeetTeamEntry />}
                readOnlyElement={<MeetTeamEntryNonHQ />} />
            }
          />
          <Route path={navRoutes['MEET_ENTRY_TEAM_SELECTION']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ENTRY_TEAM_SELECTION']}
                editElement={<TeamEntryOrgUnitSelection />} />
            }
          />
          <Route path={navRoutes['MEET_ENTRY_TEAM_HQ_SELECTION']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ENTRY_TEAM_HQ_SELECTION']}
                editElement={<TeamEntryHQOrgUnitSelection />} />
            }
          />
          <Route path={navRoutes['MEET_ENTRY_TEAM_OUA']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ENTRY_TEAM_OUA']}
                editElement={<OUAdminEntryContextRoutes navRoutes={navRoutes} />} />
            }
          />
          <Route path={navRoutes['MEET_ENTRY_ATHLETE']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ENTRY_ATHLETE']}
                editElement={<MeetAthleteEntry />} />
            }
          />
          <Route path={navRoutes['MEET_ENTRY_ATHLETE_USER']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ENTRY_ATHLETE_USER']}
                editElement={<AthleteEntryContextRoutes navRoutes={navRoutes} />} />
            }
          />
        </Routes>
      </MeetContextView >
    </MeetStaffContextProvider >
  </OrgUnitOmeMeetContextProvider>
);

const MeetRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['MEET_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_SEARCH']}
            editElement={<MeetSearchWrite />}
            readOnlyElement={<MeetSearchReadOnly />}
            simpleElement={<SimplifiedMeetSearch />} />
        }
      />
      <Route path={navRoutes['MEET_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_ADD']}
            editElement={<MeetAdd />} />
        }
      />
      <Route path={navRoutes['MEET_ADD_SESSIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_ADD_SESSIONS']}
            editElement={<MeetAddSessions />} />
        }
      />
      <Route path={navRoutes['MEET_ADD_SESSIONS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_ADD_SESSIONS_DETAIL']}
            editElement={<MeetAddSessionsDetail />} />
        }
      />
      <Route path={navRoutes['MEET_ADD_STAFF']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_ADD_STAFF']}
            editElement={<MeetAddStaff />} />
        }
      />
      <Route path={navRoutes['MEET_BY_ID']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_BY_ID']}
            editElement={<MeetById />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['MEET_SEARCH']?.route} replace />} />
    </Routes>
  );
};

const Meet = () => (
  <MeetContextProvider>
    <MeetFiltersContextProvider>
      <MeetResultsStatusContextProvider>
        <SearchMeetContextProvider>
          <OmeMeetContextProvider>
            <OmeMeetInvitationsContextProvider>
              <OmeMeetOrgUnitEntryContextProvider>
                <OmeMeetTeamEntryContextProvider>
                  <OmeMeetTeamEntryFiltersContextProvider>
                    <OmeMeetAthleteEntryContextProvider>
                      <OmeMeetAthleteEntryFiltersContextProvider>
                        <OmeMeetOrgUnitAthleteEntryContextProvider>
                          <OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider>
                            <OtsMeetSessionsContextProvider>
                              <MeetSessionsContextProvider>
                                <MeetTimesAddContextProvider>
                                  <MeetTimesAddPostContextProvider>
                                    <TimeContextProvider>
                                      <TimeFiltersContextProvider>
                                        <TimesOrganizationContextProvider>
                                          <SessionContextProvider>
                                            <MeetCompetitionSummaryContextProvider>
                                              <EventCompetitonGenderTypeContextProvider>
                                                <MyMeetsFiltersContextProvider>
                                                  <PersonOrgRoleContextProvider>
                                                    <SelectOrgUnitContextProvider>
                                                      <MeetRoutes />
                                                    </SelectOrgUnitContextProvider>
                                                  </PersonOrgRoleContextProvider>
                                                </MyMeetsFiltersContextProvider>
                                              </EventCompetitonGenderTypeContextProvider>
                                            </MeetCompetitionSummaryContextProvider>
                                          </SessionContextProvider>
                                        </TimesOrganizationContextProvider>
                                      </TimeFiltersContextProvider>
                                    </TimeContextProvider>
                                  </MeetTimesAddPostContextProvider>
                                </MeetTimesAddContextProvider>
                              </MeetSessionsContextProvider>
                            </OtsMeetSessionsContextProvider>
                          </OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider>
                        </OmeMeetOrgUnitAthleteEntryContextProvider>
                      </OmeMeetAthleteEntryFiltersContextProvider>
                    </OmeMeetAthleteEntryContextProvider>
                  </OmeMeetTeamEntryFiltersContextProvider>
                </OmeMeetTeamEntryContextProvider>
              </OmeMeetOrgUnitEntryContextProvider>
            </OmeMeetInvitationsContextProvider>
          </OmeMeetContextProvider>
        </SearchMeetContextProvider>
      </MeetResultsStatusContextProvider>
    </MeetFiltersContextProvider>
  </MeetContextProvider >
);

export default Meet;